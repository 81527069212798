html,
body {
  height: 100%;
}
.welcome-box {
  background: linear-gradient(180deg, #f4d8ff -4.94%, #ffffff 100%);
  text-align: center;
  color: #111111;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.welcome-box .girl-img {
  position: absolute;
  width: 280px;
  right: 6%;
  top: 50%;
  transform: translate(0, -50%);
}
.welcome-box .welcome-row {
  width: 54%;
  text-align: center;
  margin: 0 auto;
}
.welcome-box h1 {
  font-size: 28px;
  line-height: 33px;
}
.check-img {
  width: 18%;
  display: inline-block;
}
.check-img img {
  width: auto;
}
.welcome-text-box {
  font-size: 22px;
  line-height: 34px;
  color: #111111;
}
.ps-buttons a {
  display: inline-block;
  box-shadow: 0px 10px 25px 0px #31303e14;
  padding: 10px 16px;
  background-color: #ffffff;
  width: 45%;
  border-radius: 8px;
  display: block;
}
.ps-buttons a .btn {
  display: inline-block;
  position: relative;
  padding-left: 44px;
  text-align: left;
}
.ps-buttons a .btn img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: auto;
}
.ps-buttons a .title {
  font-size: 12px;
  line-height: 18px;
  color: #535874;
  display: block;
}
.ps-buttons a .sub-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  display: block;
  color: #111111;
}
/* .ps-buttons a {
  width: 32%;
  margin: 0 auto;
  display: block;
  margin-top: 15px;
}
.ps-buttons img {
  width: 100%;
} */
@media only screen and (max-width: 768px) {
  .check-img {
    width: 38%;
  }
  .welcome-box .welcome-row {
    width: 70%;
  }
  .welcome-box .girl-img {
    position: static;
    width: 116px;
    transform: none;
    margin-top: 30px;
  }
  .welcome-text-box {
    font-size: 16px;
    line-height: 24px;
  }
  .welcome-box .welcome-row {
    padding: 32px;
    width: auto;
  }
  .ps-buttons a {
    width: auto;
  }
  .check-img img {
    width: 90px;
  }
}
