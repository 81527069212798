html,
body {
  height: 100%;
}
.confirmation-box {
  background-blend-mode: overlay;
  background-image: url(../../../public/img/design-sans-titre-18.png),
    linear-gradient(
      54deg,
      rgba(17, 24, 47, 0.45) 40%,
      rgba(255, 255, 255, 0) 99%
    );
  text-align: center;
  color: #ffffff;
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
}
.confirmation-box .confirmation-row {
  width: auto;
  text-align: center;
}
.confirmation-box h1 {
  font-size: 1.8rem;
}
.check-img {
  width: 18%;
  display: inline-block;
}
.check-img img {
  width: 100%;
}
.cb-text-box {
  font-size: 1.4rem;
  line-height: 2rem;
  color: #eef2f8;
}
.ps-buttons a {
  width: 32%;
  margin: 0 auto;
  display: block;
  margin-top: 15px;
}
.ps-buttons img {
  width: 100%;
}
@media only screen and (max-width: 1080px) {
  .check-img {
    width: 38%;
  }
  .confirmation-box .confirmation-row {
    width: 70%;
  }
  .ps-buttons a {
    width: 90%;
  }
  .confirmation-box {
    background-image: url(../../../public/img/sans-titre.png),
      linear-gradient(
        54deg,
        rgba(17, 24, 47, 0.45) 40%,
        rgba(255, 255, 255, 0) 99%
      ) !important;
    background-position: right 0px center;
  }
}

@media only screen and (max-width: 480px) {
  .cb-text-box {
    font-size: 1rem;
  }
  .confirmation-box .confirmation-row {
    width: 90%;
  }
}
