.evolution-box {
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 12px;
  margin-bottom: 1.6%;
}
.evolution-box:nth-child(3n + 1) {
  margin-left: 0px;
}
.evolution-box .date {
  color: #999999;
  text-transform: uppercase;
}
.evolution-box .weight {
  font-size: 32px;
}
.evolution-info {
  display: flex;
  flex-shrink: 10px;
  gap: 8px;
}
.evolution-info > .item {
  padding: 10px;
  flex: 1;
  background-color: #f1f1f1;
  border-radius: 6px;
}

ul[role="navigation"] {
  list-style: none;
  margin: 0;
  display: inline-block;
  padding: 0;
}
ul[role="navigation"]:after {
  content: "";
  clear: both;
  display: block;
}
ul[role="navigation"] li {
  float: left;

  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
ul[role="navigation"] li:last-child {
  margin-right: 0;
}
ul[role="navigation"] li a {
  border: 1px solid #e0e4ea;
  padding: 7px 15px;
  border-radius: 6px;
  display: inline-block;
}
ul[role="navigation"] li.selected a {
  background: linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%);
  color: #ffffff;
}

@media only screen and (max-width: 768px) {
  .evolution-box.column {
    padding: 20px;
    margin-bottom: 20px;
  }
  .pagination {
    text-align: center;
  }
  .page-count-detail {
    text-align: center;
    margin-bottom: 15px;
  }
}
