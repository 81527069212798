.mobile-menu {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 8px 0px 44px 0px rgba(40, 38, 53, 0.4);
  backdrop-filter: blur(10px);
  position: fixed;
  left: 0;
  bottom: 0px;
  top: 0;
  right: auto;
  z-index: 100;
  padding: 30px;
  text-align: center;
  transform: translate(9999px);
  opacity: 0;
}
.mobile-menu.translate-card {
  right: 0;
  transition: opacity 500ms linear;
  transform: translate(0);
  opacity: 1;
}

.close-menu {
  position: absolute;
  top: 15px;
  right: 15px;
}

.mobile-menu ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 70px;
}
.mobile-menu ul a {
  font-size: 24px;
  color: #ffffff;
  line-height: 32px;
  text-decoration: none;
  padding: 15px 40px;
  display: inline-block;
  color: #b1c1d8;
}
.mobile-menu ul .active a {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.menu-bottom-links {
  margin-top: 20px;
}
.menu-bottom-links .b-link {
  flex: 1;
}
.menu-bottom-links .b-link a {
  color: #b1c1d8;
}
.mobile_menu {
  display: none;
}
