.price-box {
  box-shadow: 0px 10px 25px 0px #31303e14;
  border-radius: 12px;
  padding: 32px;
  background-color: #ffffff;
  flex: 1;
  position: relative;
  border: 1px solid #eef2f8;
}
.price-box .title {
  font-size: 19px;
  font-weight: 600;
}
.price-box.pro {
  margin-top: 0px;
  border-radius: 0 0 12px 12px;
}
.price-box .price {
  color: #159a12;
  font-size: 28px;
}
.price-box hr {
  border: 1px solid #b1c1d8;
}
.price-box.pro .price {
  background: linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.price-box .evo-pro {
  background: #eef2f8;
  padding: 4px 12px;
  border-radius: 40px;
  display: inline-block;
}
.price-box.pro .evo-pro {
  font-weight: 600;
  color: #159a12;
}
.price-box.pro::before {
  background: #ee933f;
  padding: 5px 20px;
  font-size: 12px;
  line-height: 22px;
  content: "7 jours d’essai gratuit";
  color: #ffffff;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  font-weight: 600;
  border-radius: 12px 12px 0 0;
  text-align: center;
}
.price-box.pro.has-active-plan::before {
  display: none;
}
.price-box.has-active-plan .tabs {
  display: none;
}
.price-box .active-plan-button {
  display: none;
}
.price-box.has-active-plan .active-plan-button {
  display: block;
}
.price-box.has-active-plan .plan-selection {
  display: none;
}
.price-box.has-active-plan .pro-plan-button {
  display: none;
}
.price-box ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.price-box ul li {
  background: url("/public/svg/icon_check_filled.svg");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  margin-bottom: 15px;
  padding-right: 25px;
  list-style-type: none;
  color: #535874;
  line-height: 20px;
}
.price-box ul li:last-child {
  margin-bottom: 0px;
}
.price-box ul li.locked {
  background: url("/public/svg/icon_locked.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
  color: #b1c1d8;
}

.price-box .ctm-btn.small {
  width: 100%;
}
.price-box .tabs {
  background-color: #eef2f8;
  color: #535874;
  text-transform: uppercase;
  border-radius: 6px;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
}
.price-box .tabs span {
  border: 2px solid #eef2f8;
  border-radius: 6px;
  display: inline-block;
  flex: 1;
  font-weight: 600;
  color: #535874;
  cursor: pointer;
  text-align: center;
}
.price-box .tabs span.active {
  background-color: #ffffff;
  color: #111111;
  box-shadow: 0px 1px 4px 0px #31303e21;
}
.plan-selection {
  min-height: 42px;
}
.pro .plan-selection {
  min-height: 33px;
}
@media only screen and (max-width: 768px) {
  .price-box {
    flex: 0 0 65%;
    padding: 20px;
    text-align: left;
  }
  .price-box .title {
    margin-top: 0px;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .price-box ul li {
    font-size: 13px;
    margin-bottom: 12px;
  }
  .price-box hr {
    display: none;
  }
  .price-box button {
    margin-bottom: 20px;
  }
  .price-box .tabs {
    margin-top: 10px;
  }
  .plan-selection {
    min-height: 51px;
  }
}
