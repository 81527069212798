.header_content {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #272833;
  padding: 14px 20px 12px;
}
.header_content .main_logo {
  width: 135px;
}
.header_content .main_logo img {
  max-width: 80px;
}
.header_content img {
  height: 48px;
}

.header_content .header-tab {
  display: flex;
  align-items: center;
  gap: 22px;
  flex: 1;
}
.header_content .header-tab a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.header_content .header-tab a:hover {
  color: #ffffff;
}
.header_content .header-tab span {
  font-size: 15px;
  color: #b1c1d8;
  font-weight: 400;
  text-transform: uppercase;
}

.basic-info-form .input-container .input_label {
  color: black;
  font-size: 13px;
  font-weight: 400;
}
.basic-info-form .input-container .custom-input {
  border: 1px solid #b1c1d8;
}

.danger_text {
  color: #e04040;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}

.danger_primary {
  color: #535874;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  text-decoration: underline;
}

.tab-container {
  display: flex;
  border-bottom: 1px solid rgba(204, 204, 204, 1);
}
.tab-container a {
  display: block;
  text-align: center;
  flex: 1;
  padding: 15px;
  font-weight: 600;
  color: rgba(153, 153, 153, 1);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
}

.tab-container a.active {
  border-bottom: 2px solid;
  color: rgba(34, 34, 34, 1);
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%);
}
.account-header {
  font-size: 28px;
  font-weight: 700;
}
.subscription {
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
}
.subscription:first-child {
  padding-left: 0px;
}
.subscription:last-child {
  padding-right: 0px;
}
.subscription-wrapper {
  margin-top: 20px;
  color: #111111;
}
.subscription-box {
  box-shadow: 0px 10px 25px 0px #31303e14;
  padding: 16px 24px 32px 24px;
  border: 1px solid #eef2f8;
  border-radius: 12px;
}
.subscription-box .title {
  font-size: 19px;
  font-weight: 600;
}
.subscription-box .price {
  color: #159a12;
  font-size: 28px;
}
.evo-plan-header {
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 60px;
}
.back-link {
  color: rgba(39, 40, 51, 1);
}
.change-password {
  margin: 0 auto;
  float: none;
}

@media only screen and (max-width: 768px) {
  .header_content .main_logo {
    position: unset;
    transform: unset;
    width: auto;
  }
  .header_content .main_logo svg {
    margin-right: 10px;
  }
  .header_content img {
    height: 35px;
  }
  .header_content .header-tab {
    display: none;
  }
  .subscription-wrapper {
    display: block;
  }
  .subscription {
    margin-bottom: 20px;
  }
  .main_logo {
    display: flex;
    gap: 10px;
  }
  .mobile_menu {
    display: block !important;
  }
  .ctm-btn.secondary-btn,
  .ctm-btn.danger-btn,
  .ctm-btn.small {
    width: 100%;
  }
  .subscription-wrapper {
    width: auto;
  }
  .account-header {
    text-align: center;
  }
  .evo-plan-header {
    text-align: center;
  }
}

.cursor-pointer {
  cursor: pointer;
  user-select: none;
}
