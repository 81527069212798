@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-size: 14px;
}

::-webkit-scrollbar {
  display: none;
}
.font-poppins {
  font-family: "Poppins", sans-serif;
}

/********** Grid css Start ************* */

.row,
.column {
  box-sizing: border-box;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}
.column {
  position: relative;
  display: block;
}

/* Mobile Devices Styling */
.column-1,
.column-2,
.column-3,
.column-4,
.column-5,
.column-6,
.column-7,
.column-8,
.column-9,
.column-10,
.column-11,
.column-12 {
  width: auto;
  float: none;
}
.column + .column {
  margin-left: 0;
}

/* General Styling */
.row {
  margin-bottom: 0;
}
.column {
  padding: 20px;
  min-height: 30px;
  margin-bottom: 10px;
}
.row:last-child .column:last-child {
  margin-bottom: 0;
}

/* Larger Screens Styling */
@media only screen and (min-width: 768px) {
  .column {
    position: relative;
    float: left;
    margin-bottom: 0;
  }
  .column + .column {
    margin-left: 1.6%;
  }
  .row {
    margin-bottom: 10px;
  }
  .row .row:last-child {
    margin-bottom: 0;
  }
  .column-1 {
    width: 6.86666666667%;
  }
  .column-2 {
    width: 15.3333333333%;
  }
  .column-3 {
    width: 23.8%;
  }
  .column-4 {
    width: 32.2666666667%;
  }
  .column-5 {
    width: 40.7333333333%;
  }
  .column-6 {
    width: 49.2%;
  }
  .column-7 {
    width: 57.6666666667%;
  }
  .column-8 {
    width: 66.1333333333%;
  }
  .column-9 {
    width: 74.6%;
  }
  .column-10 {
    width: 83.0666666667%;
  }
  .column-11 {
    width: 91.5333333333%;
  }
  .column-12 {
    width: 100%;
  }
}

/********** Grid css End ************* */

/* COMMON */

.customButton {
  background: linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%);
  color: #ffffff;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  text-transform: uppercase;
}

.ctm-btn {
  padding: 16px 30px;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.ctm-btn:disabled {
  cursor: default;
  opacity: 0.8;
}
.ctm-btn.secondary-btn {
  background: rgba(39, 40, 51, 1);
  padding: 10px 35px;
  width: auto;
  color: #fff;
  font-size: 12px;
}
.ctm-btn.secondary-btn:hover {
  background: rgba(39, 40, 51, 1);
}

.ctm-btn.danger-btn {
  background: none;
  padding: 10px 35px;
  width: auto;
  font-size: 12px;
  border: 1px solid rgba(224, 64, 64, 1);
  color: rgba(224, 64, 64, 1);
}
.ctm-btn.danger-btn:hover {
  background: none;
}

.ctm-btn.small {
  padding: 10px 35px;
  width: auto;
  color: #fff;
  font-size: 12px;
}

.ctm-btn.outline {
  background: linear-gradient(#ffffff 0 0) padding-box,
    linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%) border-box;
  color: #313149;
  border: 2px solid transparent;
  border-radius: 44px;
  color: #8e3cff;
}
.ctm-btn.outline:hover {
  background: linear-gradient(#f4d8ff 0 0) padding-box,
    linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%) border-box;
}
.customButton:hover {
  background: linear-gradient(180deg, #8e3cff 0%, #cd4aff 100%);
}

.password_input_container {
  position: relative;
}

.password_input_container img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.input_label {
  font-weight: 400;
  color: #fff;
  font-size: 13px;
}

.text-upper {
  text-transform: uppercase;
}

/* COMMON END*/

.auth_background {
  background: linear-gradient(
      191.96deg,
      rgba(51, 68, 112, 0.9) -2.93%,
      rgba(28, 31, 40, 0.8) 106.87%
    ),
    url("/public/img/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.auth_title {
  font-weight: 700;
  font-size: 40px;
  color: #fff;
  text-align: center;
}

.auth_container {
  max-width: 470px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
}

.custom-input {
  padding: 12px 14px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid #fff;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 13px;
  margin: 5px 0;
}

.custom-input:disabled {
  background-color: #f0f0f0;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.from-container {
  width: 100%;
}

.form-select {
  border: 1px solid #e0e4ea;
  padding: 6px 8px;
  border-radius: 6px;
}

.error {
  color: red;
  font-weight: 400;
  font-size: 12px;
}
/*** Updated signup/login css start ***/
.auth-box {
  padding: 15px 0px 0px 0px;
  max-width: 1320px;
  margin: 0 auto;
}
.auth-title {
  color: #000000;
  font-size: 28px;
  font-weight: 700;
}
.auth-box .custom-input {
  padding: 12px 14px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid #000000;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 13px;
  margin: 5px 0;
  color: #000000;
}
.auth-box .input-label {
  font-weight: 400;
  color: #000000;
  font-size: 13px;
}
.plan-details-box {
  position: relative;
  padding-top: 36%;
  padding-right: 15%;
}
.auth-form-box {
  padding-left: 15%;
}
.plan-details-box > img {
  position: absolute;
  max-width: 100%;
  top: 0px;
  right: 35px;
}
.plan-details-box .detail-box {
  padding: 25px;
  box-shadow: 0px 10px 25px 0px rgba(49, 48, 62, 0.08);
  background-color: #ffffff;
  position: relative;
  line-height: 24px;
}
.plan-details-box .plan .name {
  font-size: 26px;
  font-weight: 700;
}
.plan-details-box .plan .price {
  background: linear-gradient(90deg, #8e3cff 0%, #cd4aff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
}
.plan-details-box .details ul li {
  background: url("/public/svg/icon_check_filled.svg");
  background-position: 100% 5px;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding-right: 25px;
  list-style-type: none;
  color: #535874;
}
.login-left-box {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.login-right-box {
  background-color: #ffffff;
  padding-left: 8%;
}
.login-wrapper .auth-box {
  padding: 0px;
}
.login-wrapper {
  position: relative;
}
.login-wrapper::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(199, 64, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  top: 0px;
  bottom: 0px;
  width: 50%;
}
/*** Updated signup/login css end ***/

.p-0 {
  padding: 0;
}
.pl-0 {
  padding-left: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-20 {
  padding-right: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}

.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0px;
}
.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}

.mb-80 {
  margin-bottom: 80px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.clr-white {
  color: #fff !important;
}

.clr-gry {
  color: #535874;
  font-weight: 400;
}
.w-100 {
  width: 100%;
}
.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-15 {
  font-size: 15px;
}

.text-14 {
  font-size: 14px;
}
.text-12 {
  font-size: 12px;
}
.lh-18 {
  line-height: 18px;
}
.text-white {
  color: #ffffff;
}
.text-black {
  color: #000000;
}
.text-left {
  text-align: left !important;
}
.v-align-middle {
  vertical-align: middle;
}
.from-text {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

hr {
  border-color: #b1c1d8;
}

.or-text {
  overflow: hidden;
  text-align: center;
  margin: 20px 0px;
}

.or-text:before,
.or-text:after {
  background-color: #b1c1d8;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 45%;
}

.or-text:before {
  right: 1em;
  margin-left: -50%;
}

.or-text:after {
  left: 1em;
  margin-right: -50%;
}

.social-media-login {
  width: 100%;
}
.facebook-btn {
  background-color: #1877f2;
  color: #fff;
}

.facebook-btn img {
  margin-right: 10px;
}

.google-btn {
  background-color: #fff;
  color: #272833;
  border: 1px solid #eef2f8;
  box-shadow: 0px 1px 4px 0px #31303e21;
}

.google-btn img {
  margin-right: 10px;
}

.text-dec-non {
  text-decoration: none !important;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}

.d-inline-block {
  display: inline-block;
}
.d-flex {
  display: flex;
}
.no-text-decoration {
  text-decoration: none;
}
.loader-wrapper {
  position: relative;
}
.loader {
  background: linear-gradient(#8e3cff, #cd4aff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 7px), #fff 0);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 7px), #fff 0);
  border-radius: 50%;
  position: absolute;
  inset: calc(50% - 30px);
  animation: rot 2s linear infinite;
}

@keyframes rot {
  100% {
    transform: rotate(360deg);
  }
}

.show-mobile {
  display: none;
}
.mw-100 {
  max-width: 100%;
}
/*** Accordian css start ***/
.accordion-item {
  box-shadow: 0px 1px 4px 0px #31303e21;
  padding: 20px;
  margin-bottom: 10px;
  background-color: #ffffff;
}
.accordion-title {
  font-weight: 600;
  position: relative;
  padding-right: 40px;
}
.accordian-icon {
  position: absolute;
  top: 2px;
  right: 5px;
}
.accordian-icon img {
  width: 20px;
}

.accordion-content {
  font-size: 1rem;
  margin-top: 20px;
  color: #535874;
}
/*** Accordian css end ***/
@media only screen and (max-width: 768px) {
  .auth_title {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .ctm-btn {
    padding: 12px 30px;
  }
  .custom-input,
  .auth-box .custom-input {
    font-size: 16px;
  }
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
  /*** Updated signup/login css Start ***/
  .plan-details-box > img {
    max-width: 50%;
    right: 0px;
  }
  .plan-details-box {
    padding-top: 5px;
    padding-right: 0px;
  }
  .auth-form-box {
    padding-left: 0px;
  }
  .auth-title {
    max-width: 50%;
  }
  .plan-details-box .plan .name {
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 17px;
  }
  .plan-details-box .plan .price {
    font-size: 16px;
    line-height: 20px;
  }
  .plan-details-box .plan .description {
    font-size: 12px;
    margin-top: 5px;
    line-height: 14px;
  }
  .plan-details-box .detail-box {
    padding: 15px;
  }
  .more-detail {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 12px;
  }
  .auth-box {
    padding: 0px;
  }
  .auth-box .column:first-child {
    padding-bottom: 0;
    margin-bottom: 0px;
  }
  .auth-box .from-container .or-text {
    margin: 10px 0px;
  }
  .back-icon {
    width: 18px;
  }
  .auth-box .social-media-login {
    margin-top: 0px;
  }
  .login-left-box {
    display: block;
    text-align: center;
  }
  .login-wrapper .auth-title {
    max-width: none;
  }
  .login-wrapper::before {
    display: none;
  }
  .login-left-box {
    height: auto;
  }
  .login-right-box {
    background: url("/public/img/login_left_img.png") no-repeat;
    background-size: 80%;
    background-position: 50% 0;
    padding-top: 174px;
    padding-left: 20px;
  }
  .login-wrapper .auth-title {
    font-size: 19px;
  }
  .logo-box > img {
    width: 96px;
  }
  /*** Updated signup/login css End ***/
  /*** Accordian css start ***/
  .accordion-item {
    padding: 15px;
  }
  /*** Accordian css end ***/
}
