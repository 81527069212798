@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&display=swap");
.container {
  max-width: 1380px;
  margin: 0 auto;
}
.pricing-banner {
  background: linear-gradient(180deg, #f4d8ff -4.94%, #ffffff 100%);
  padding: 80px 0;
}
.header {
  font-size: 44px;
  line-height: 54px;
  margin-bottom: 10px;
}
.banner-img {
  mix-blend-mode: multiply;
  max-width: 100%;
  margin-top: 40px;
}
.discover-link {
  color: #a462ff;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  text-transform: uppercase;
}
.price-box-wrapper {
  column-gap: 20px;
}
.free-trial-steps {
  display: flex;
  width: 84%;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
}
.free-trial-steps .step-box {
  flex: 1;
  position: relative;
}
.free-trial-steps .step-box .icon {
  position: relative;
  z-index: 1;
}
.step-box span {
  position: relative;
  display: inline-block;
}
.step-box::before {
  content: "";
  height: 16px;
  background: #a462ff;
  opacity: 0.2;
  position: absolute;
  top: 10%;
  right: 0;
  width: 100%;
}
.step-box:first-child::before,
.step-box:last-child::before {
  width: 50%;
}
.step-box:last-child::before {
  left: 0;
  right: auto;
}
.step-box .title {
  font-size: 28px;
  line-height: 36px;
}
.step-box .description {
  line-height: 24px;
}
.pricing-section {
  width: 52%;
  margin: 0 auto;
  padding: 40px 0;
}
.pricing-section.faq {
  width: 80%;
  padding: 60px 0px;
}
.pricing-section.faq p {
  font-size: 16px;
  line-height: 26px;
}
.pricing-section p {
  line-height: 38px;
  font-size: 24px;
}
.pricing-section button {
  margin: 0 auto;
}
.pricing-section-side-by-side .pricing-section {
  width: 85%;
}
.pricing-section.right {
  padding-top: 200px;
}
.pricing-section .accordion-item {
  font-size: 18px;
  line-height: 24px;
  border-radius: 6px;
}
.grey-bg-box {
  background-color: #f7f8fb;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .price-box-wrapper {
    overflow: auto;
    padding-top: 32px;
    padding-bottom: 35px;
    padding-left: 20px;
    padding-right: 20px;
    column-gap: 10px;
  }
  .header {
    font-size: 28px;
    line-height: 36px;
  }
  .pricing-banner {
    padding: 0;
    background: none;
  }
  .container {
    width: 100%;
  }
  .free-trial-steps {
    display: block;
    text-align: left;
    width: 92%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .free-trial-steps .step-box .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .free-trial-steps .step-box .icon img {
    width: 40px;
  }
  .free-trial-steps .step-box {
    padding-left: 65px;
  }
  .step-box .title {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 19px;
    line-height: 30px;
    padding-top: 3px;
  }
  .step-box::before {
    height: 100%;
    width: 4px;
    left: 5%;
  }
  .step-box:first-child::before {
    width: 4px;
  }
  .step-box:last-child::before {
    display: none;
  }
  .step-box .description {
    margin: 0;
    padding-bottom: 15px;
  }
  .pricing-section {
    width: auto;
    padding: 20px;
  }
  .pricing-section p {
    font-size: 16px;
    line-height: 24px;
  }
  .pricing-section.right {
    padding-top: 20px;
  }
  .pricing-section-side-by-side .pricing-section {
    width: auto;
  }
  .pricing-banner .container {
    background: url("/public/img/marketing/pricing_banner_mobile.webp")
      no-repeat;
    background-position: 100% 20px;
    background-size: 52%;
    background-blend-mode: multiply;
  }
  .pricing-banner .header {
    width: 45%;
    margin-top: 10px;
  }
  .pricing-mbl-banner {
    background: linear-gradient(180deg, #f4d8ff -4.94%, #ffffff 100%);
    mix-blend-mode: multiply;
    margin-bottom: 0px;
  }
  .pricing-banner .column-8 {
    padding-top: 0px;
    padding: 0px;
  }
  .free-trial-box {
    padding: 0 20px;
  }
  .free-trial-box .header {
    font-size: 19px;
    line-height: 30px;
  }
  .free-trial-box > p {
    margin-top: 5px;
  }
  .pricing-section .accordion-item {
    font-size: 16px;
  }
  .pricing-section.faq p {
    font-size: 12px;
    line-height: 18px;
  }
  .pricing-section.faq {
    width: auto;
    padding: 30px 20px;
  }
  .pricing-section .header {
    margin-top: 0px;
  }
  .pricing-section-side-by-side .pricing-section {
    padding: 0px;
  }
  .pricing-section-side-by-side {
    margin-top: 15px;
  }
}
