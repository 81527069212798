@import url("https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@300;400;500;600;700&display=swap");
.marketing-site {
  font-size: 1rem;
  line-height: 1.5rem;
}
.marketing-offers {
  background-image: url(/public/img/marketing/image-de-fond-desktop.webp);
  padding: 40px 20px;
  background-size: cover;
}
.offer-box {
  max-width: 1260px;
  margin: 0 auto;
}
.offer-box img {
  max-width: 100%;
}
.header-h1 {
  font-size: 3.125rem;
  color: #ffffff;
  line-height: 3.4375rem;
  font-family: "Cormorant SC", serif;
}
.header-h2 {
  font-size: 1.5625rem;
  color: #ffffff;
  line-height: 1.875rem;
}
.header-h3 {
  font-size: 1.875rem;
  color: #ffffff;
  line-height: 2.8125rem;
}

.header-h4 {
  font-size: 1.1875rem;
}
.mk-button {
  background: linear-gradient(90deg, #ff764a 0%, #ff4a4a 100%);
  padding: 11px 40px 11px 40px;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 44px;
  color: #ffffff;
  box-shadow: none;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
}
.mk-button * {
  vertical-align: middle;
}

.offer-title {
  align-items: center;
}
.offer-title .price {
  flex: 1;
  font-size: 1.25rem;
  color: #ffffff;
}
.offer-title .price span {
  font-weight: 400;
  font-size: 1rem;
  text-decoration-line: line-through;
}
.app-marketing-box {
  padding: 60px 20px 0px 20px;
}
.app-marketing-box img {
  max-width: 550px;
}
.grey-bg-box {
  background-color: #f7f8fb;
  padding: 40px 20px;
}
.testimonial-box .title {
  font-size: 1.125rem;
}
.testimonial-box .header-h4 {
  padding: 0 30px;
}
.testimonial-box-slider {
  max-width: 980px;
  line-height: 1.5rem;
}
.testimonial-box-slider img {
  display: inline-block;
}
.testimonial-box-slider .rattings img {
  max-width: 120px;
}
.testimonial-box .slick-dots li button {
  height: 6px;
  width: 28px;
  font-size: 0px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.testimonial-box .slick-dots li.slick-active button {
  background-color: #000000;
}
.testimonial-box .slick-dots li {
  width: auto;
  height: auto;
}
.testimonial-box .slick-dots li button:before {
  display: none;
}

@media only screen and (max-width: 768px) {
  .marketing-offers {
    background-image: url(/public/img/marketing/image-fond-mobile.webp);
  }
  .app-marketing-box img {
    max-width: 100%;
  }
}
